import { Routes } from '@angular/router';
import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations';

export function routeAnimation(routes: Routes, speed = '250ms') {
  const routeNames = routes.map(r => r.data?.['animation']).filter(name => !!name);

  const forwardStates = [];
  for (let i = 0; i < routeNames.length - 1; i++) {
    forwardStates.push(routeNames[i] + ' => ' + routeNames[i + 1]);
  }
  const backwardStates = [];
  for (let i = 0; i < routeNames.length - 1; i++) {
    backwardStates.push(routeNames[i + 1] + ' => ' + routeNames[i]);
  }

  return trigger('routeAnimations', [
    transition(forwardStates.join(', '), [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%',
        }),
      ]),
      query(':enter', [style({ right: '-100%', opacity: 0 })]),
      query(':leave', animateChild()),
      group([
        query(':leave', [animate(`${speed} ease-in-out`, style({ right: '100%', opacity: 0 }))]),
        query(':enter', [animate(`${speed} ease-in-out`, style({ right: '0%', opacity: 1 }))]),
      ]),
      query(':enter', animateChild()),
    ]),
    transition(backwardStates.join(', '), [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        }),
      ]),
      query(':enter', [style({ left: '-100%', opacity: 0 })]),
      query(':leave', animateChild()),
      group([
        query(':leave', [animate(`${speed} ease-in-out`, style({ left: '100%', opacity: 0 }))]),
        query(':enter', [animate(`${speed} ease-in-out`, style({ left: '0%', opacity: 1 }))]),
      ]),
      query(':enter', animateChild()),
    ]),
  ]);
}
