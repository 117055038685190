<div [@routeAnimations]="routeAnimationData$ | async" class="w-full h-full">
  <router-outlet></router-outlet>
</div>

<div class="language-button">
  <div class="button-click">
    <button
      nz-button nz-dropdown nzGhost nzSize="small" class="language-button"
      [nzDropdownMenu]="menu"
      nzPlacement="bottomRight"
    >
      <fa-icon [icon]="fasLanguage"></fa-icon>
    </button>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item (click)="changeLanguage('EN')">
          English
        </li>
        <li nz-menu-item (click)="changeLanguage('DE')">
          German
        </li>
      </ul>
    </nz-dropdown-menu>
  </div>
</div>
