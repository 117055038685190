import { Route } from '@angular/router';

export const appRoutes: Route[] = [
  { path: '', pathMatch: 'full', redirectTo: 'intro' },
  {
    path: 'intro',
    data: { animation: 'introPage' },
    loadComponent: () => import('./pages/intro-page/intro-page.component').then(m => m.IntroPageComponent),
  },
  {
    path: 'customer-data-start',
    data: { animation: 'customerDataStartPage' },
    loadComponent: () =>
      import('./pages/customer-data-page/customer-data-page.component').then(m => m.CustomerDataPageComponent),
  },
  {
    path: 'questionnaire-intro',
    data: { animation: 'questionnaireIntroPage' },
    loadComponent: () =>
      import('./pages/questionnaire-intro-page/questionnaire-intro-page.component').then(
        m => m.QuestionnaireIntroPageComponent,
      ),
  },
  {
    path: 'questionnaire',
    data: { animation: 'questionnairePage' },
    loadComponent: () =>
      import('./pages/questionnaire-page/questionnaire-page.component').then(m => m.QuestionnairePageComponent),
  },
  {
    path: 'measure-intro',
    data: { animation: 'measureIntroPage' },
    loadComponent: () =>
      import('./pages/measure-intro-page/measure-intro-page.component').then(m => m.MeasureIntroPageComponent),
  },
  {
    path: 'measure',
    data: { animation: 'measurePage' },
    loadComponent: () => import('./pages/measure-page/measure-page.component').then(m => m.MeasurePageComponent),
  },
  {
    path: 'roadmap-intro',
    data: { animation: 'roadmapIntroPage' },
    loadComponent: () =>
      import('./pages/roadmap-intro-page/roadmap-intro-page.component').then(m => m.RoadmapIntroPageComponent),
  },
  {
    path: 'roadmap',
    data: { animation: 'roadmapPage' },
    loadComponent: () => import('./pages/roadmap-page/roadmap-page.component').then(m => m.RoadmapPageComponent),
  },
  {
    path: 'result',
    data: { animation: 'resultPage' },
    loadComponent: () => import('./pages/result-page/result-page.component').then(m => m.ResultPageComponent),
  },
  {
    path: 'customer-data-end',
    data: { animation: 'customerDataEndPage' },
    loadComponent: () =>
      import('./pages/customer-data-page/customer-data-page.component').then(m => m.CustomerDataPageComponent),
  },
];
