import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivationEnd, Router, RouterOutlet } from '@angular/router';
import { routeAnimation } from '../../animations/route.animation';
import { appRoutes } from '../../app.routes';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { NzDropdownButtonDirective, NzDropDownDirective, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { NzMenuDirective, NzMenuItemComponent } from 'ng-zorro-antd/menu';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { TranslocoService } from '@jsverse/transloco';
import { distinctUntilChanged, filter, map, Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'pd-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    FaIconComponent,
    NzButtonComponent,
    NzDropDownDirective,
    NzDropdownButtonDirective,
    NzDropdownMenuComponent,
    NzMenuDirective,
    NzMenuItemComponent,
    RouterOutlet,
  ],
  animations: [routeAnimation(appRoutes, '150ms')],
})
export class AppComponent {
  protected readonly fasLanguage = faLanguage;
  protected readonly routeAnimationData$: Observable<string>;

  constructor(
    private readonly router: Router,
    private translocoService: TranslocoService,
  ) {
    this.routeAnimationData$ = this.router.events.pipe(
      filter(event => event instanceof ActivationEnd),
      map(event => event.snapshot.data['animation']),
      distinctUntilChanged(),
    );
  }

  changeLanguage(language: string) {
    this.translocoService.setActiveLang(language);
  }
}
