import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoLoader } from '@jsverse/transloco';
import { de_DE, en_US, NzI18nService } from 'ng-zorro-antd/i18n';
import de from '@angular/common/locales/de';
import en from '@angular/common/locales/en';

@Injectable({ providedIn: 'root' })
export class HttpLoader implements TranslocoLoader {
  constructor(
    private readonly http: HttpClient,
    private readonly nzI18nService: NzI18nService,
  ) {}

  getTranslation(language: string) {
    if (language.startsWith('/')) {
      language = language.substring(1);
    }
    registerLocaleData(language === 'DE' ? de : en);
    this.nzI18nService.setLocale(language === 'DE' ? de_DE : en_US);
    return this.http.get(`/assets/i18n/${language.toLowerCase()}.json?hash=${Date.now()}`);
  }
}
