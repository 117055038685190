import { provideTransloco, translocoConfig } from '@jsverse/transloco';
import { HttpLoader } from './http.loader';

export function provideI18n(production: boolean) {
  return [
    provideTransloco({
      config: translocoConfig({
        prodMode: production,
        availableLangs: ['EN', 'DE'],
        reRenderOnLangChange: true,
        fallbackLang: 'EN',
        defaultLang: 'EN',
        missingHandler: {
          useFallbackTranslation: true,
        },
      }),
      loader: HttpLoader,
    }),
  ];
}
